import React, { FC, useState, useEffect } from 'react';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { CameraTwoTone, CloseCircleOutlined } from '@ant-design/icons';
// import imageCompression from 'browser-image-compression';
import { Div } from '../../framework';
import { openModal } from '../../store/app.slice';
import { MediaManagerItem } from '../../helper/type';
import Compressor from 'compressorjs';

import './MediaGalleryManager.scss';

type Props = {
  value?: MediaManagerItem[];
  onChange?: (mediaItems: MediaManagerItem[]) => void;
  onStatusChange?: (isProcessing: boolean) => void;
};

const MediaGalleryManager: FC<Props> = ({
  value = [],
  onChange,
  onStatusChange,
}) => {
  const dispatch = useDispatch();
  const [mediaItems, setMediaItems] = useState<MediaManagerItem[]>([]);
  const [compressingCount, setCompressingCount] = useState(0);
  const [isInvalidFiles, setIsInvalidFiles] = useState(false);

  useEffect(() => {
    if (value.length !== mediaItems.length) {
      setMediaItems(value);
    }
  }, [value]);

  useEffect(() => {
    onStatusChange && onStatusChange(compressingCount > 0);
  }, [compressingCount]);

  const handleAddNewPhotos = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files ?? [];
    const validFiles = [];
    let isInvalidFiles = false;
    const validImageTypes = ['image/jpeg', 'image/png', 'image/webp'];
    for (let i = 0; i < files.length; i++) {
      if (validImageTypes.includes(files[i].type)) validFiles.push(files[i]);
      else isInvalidFiles = true;
    }

    setIsInvalidFiles(isInvalidFiles);

    if (!validFiles.length) return;

    const newMediaItems: MediaManagerItem[] = [];
    setCompressingCount(validFiles.length);

    // for (let i = 0; i < files.length; i++) {
    //   const imageFile = files[i];

    //   const originalOptions = {
    //     maxSizeMB: 0.45,
    //     useWebWorker: true,
    //     fileType: 'image/jpeg',
    //     alwaysKeepResolution: true,
    //     preserveExif: true,
    //   };

    //   const thumbnailOptions = {
    //     maxWidthOrHeight: 384,
    //     useWebWorker: false,
    //     fileType: 'image/jpeg',
    //     alwaysKeepResolution: true,
    //     preserveExif: true,
    //   };

    //   try {
    //     const originalImage =
    //       imageFile.size / 1024 > 500
    //         ? await imageCompression(imageFile, originalOptions)
    //         : imageFile;

    //     const thumbnailImage = await imageCompression(
    //       imageFile,
    //       thumbnailOptions
    //     );

    //     newMediaItems.push({
    //       url: {
    //         original: URL.createObjectURL(originalImage),
    //         thumbnail: URL.createObjectURL(thumbnailImage),
    //         type: 'photo',
    //       },
    //       file: {
    //         original: originalImage,
    //         thumbnail: thumbnailImage,
    //       },
    //       isNew: true,
    //     });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    const compress = (image: File, opts?: Compressor.Options): Promise<File> =>
      new Promise(
        (resolve, reject) =>
          new Compressor(image, {
            ...opts,
            success: (result) => {
              if (result instanceof File) {
                resolve(result);
              } else {
                const file = new File([result], image.name, {
                  type: result.type,
                });
                resolve(file);
              }
            },
            error: reject,
          })
      );

    for (let i = 0; i < validFiles.length; i++) {
      const imageFile = validFiles[i];

      const originalImage =
        imageFile.size / 1024 > 500
          ? await compress(imageFile, {
              quality: 0.6,
              retainExif: true,
            })
          : imageFile;

      const thumbnailImage = await compress(imageFile, {
        quality: 0.6,
        maxHeight: 384,
        maxWidth: 384,
        retainExif: true,
      });

      newMediaItems.push({
        url: {
          original: URL.createObjectURL(originalImage),
          thumbnail: URL.createObjectURL(thumbnailImage),
          type: 'photo',
        },
        file: {
          original: originalImage,
          thumbnail: thumbnailImage,
        },
        isNew: true,
      });
    }

    const newItems = [...newMediaItems, ...mediaItems];
    onChange && onChange(newItems);

    setMediaItems(newItems);
    setCompressingCount(0);
  };

  const handleRemoveImage = (index: number) => {
    const newItems = mediaItems.filter((v, i) => index !== i);
    onChange && onChange(newItems);
    setMediaItems(newItems);
  };

  const openGallery = (index = 0) => {
    dispatch(
      openModal({
        modal: 'media-gallery',
        config: {
          context: {
            gallery: mediaItems.map((item) => item.url),
            index,
          },
        },
      })
    );
  };

  const blankSpaces =
    ((compressingCount > 0 ? compressingCount : mediaItems.length) + 1) % 3;

  return (
    <Div className='component-media-upload-manager'>
      <input
        id='media-upload'
        type='file'
        accept='image/*'
        onChange={handleAddNewPhotos}
        multiple
        style={{ display: 'none' }}
      />
      <Div flex className='gallery-preview'>
        <Div className='image-container'>
          <label htmlFor='media-upload' className='upload-media-button'>
            <div>
              <CameraTwoTone />
            </div>
            Add New Photos
          </label>
        </Div>
        {compressingCount > 0
          ? new Array(compressingCount).fill(1).map((value, index) => (
              <Div className='image-container' key={index}>
                <Div className='processing-placeholder'>
                  <span>Processing</span>
                  <Spin />
                </Div>
              </Div>
            ))
          : null}
        {mediaItems.map((item, index) => (
          <Div key={index} className='image-container'>
            <img src={item.url.original} onClick={() => openGallery(index)} />
            {compressingCount === 0 ? (
              <button
                className='close'
                onClick={() => handleRemoveImage(index)}
              >
                <CloseCircleOutlined />
              </button>
            ) : null}
          </Div>
        ))}
        {new Array((blankSpaces === 0 ? 0 : 3) - blankSpaces)
          .fill(1)
          .map((value, index) => (
            <Div className='image-container' key={index}>
              <Div className='image-placeholder' />
            </Div>
          ))}
      </Div>
      {isInvalidFiles && (
        <Div className='invalid-format-error'>
          Invalid file format. Only JPG, PNG and webp are accepted.
        </Div>
      )}
    </Div>
  );
};

export default MediaGalleryManager;
